// src/scss/components/tabs.scss

.tabs {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 5px 0 0;
    border-bottom: 1px solid rgba(45, 45, 45, 0.07); // 変数化しても良い
  }
  
  .tab-list {
    display: flex;
    align-items: flex-end;
    @include sp {
      justify-content: center;
    }
  }
  
  .tab-item {
    flex-flow: row;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    @include sp {
      width: 30vw;
      min-width: 23vw;
    }
    height: 45px;
    padding: 0 10px;
    margin-left: 5px;
    text-align: center;
    background-color: $color-bg-f8f8f8;
    color: #a0a0a0; // 変数化しても良い
    border-radius: 4px 4px 0 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 1;
    cursor: pointer;
  
    &:first-child {
      margin-left: 0;
    }
  
    &[aria-selected=true] {
      position: relative;
      z-index: 1;
      height: 50px;
      padding-top: 4px;
      color: $color-text;
      cursor: default;
      background-color: $color-background;
      border: solid rgba(45, 45, 45, 0.07);
      border-width: 1px 1px 0;
      opacity: 1;
  
      &::before {
        position: absolute;
        bottom: -1px;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 2px;
        content: "";
        background-color: $color-background;
      }
    }
  }  