// review.scss

.review {
    margin-top: 30px;
  
    h2 {
      font-size: 24px;
      background: $color-bg-f8f8f8;
      padding: 10px;
      border: 1px solid $color-review-border;
    }
  
    button {
      margin-top: 10px;
    }
  }
  