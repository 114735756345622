// components/search.scss

.search-heading {
    .flex {
      align-items: flex-end; // 'end' → 'flex-end' に修正
      margin-bottom: 24px;
      @include sp {
        width: 100%;
        justify-content: center;
      }
    }
  
    h1 {
      font-size: 28px;
      font-weight: 600;
      line-height: 1;
      margin-right: 30px;
      margin-bottom: 10px;
    }
  }
  
  .search-count {
    position: relative;
    background: #c1c1c1;
    padding: 15px 30px 10px;
    font-size: 13px;
    color: #fff; // 'ffff' は誤り。正しくは '#fff' または 'white'
    border-bottom: 0;
    width: fit-content;
    border-radius: 4px;
    text-align: center;
  
    span {
      display: inline-block;
      font-weight: bold;
      line-height: 1.1;
      font-size: 29px;
      margin-top: 5px;
      margin-right: 3px;
    }
  
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 18px;
      left: -8px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 11px solid #c1c1c1;
    }
  }
  
  .search-page {
    margin-left: 10px;
  
    p {
      font-size: 0.6rem;
      margin-bottom: 10px;
      color: $color-text;
    }
  }
  
.gender-filter {
  display: flex;
  height: 34px;
  @include sp {
    justify-content: center;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    padding: 0 15px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: rgba(45, 45, 45, 0.03); // '3%' → '0.03' に修正
    }

    &[aria-selected='true'] {
      background-color: rgba(45, 45, 45, 0.07);
      cursor: default;
    }

    div {
      font-size: 13px;
      font-weight: bold;
      line-height: 1;
    }

    &.men {
      color: #0064d8;
    }

    &.women {
      color: #f26868;
    }

    &.kids {
      color: #f6ca55;
    }
  }
}