.product-gallery {
    margin-bottom: 60px;

    &__title {
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        font-size: 20px;
        line-height: 1.7;
        color: $color-text;
        @include sp {
            margin-left: 16px;
        }
    }
    &__container {
        margin-top: 15px;
        filter: drop-shadow(rgba(0, 0, 0, 0.03) 0px 7px 24px);
        backface-visibility: hidden;
        position: relative;
    }
    &__scrollable {
        overflow: scroll hidden;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        @include sp {
            padding-left: 16px;
        }
    }
    &__items {
        display: inline-grid;
        grid-auto-flow: column;
        overflow: hidden;
        grid-template-rows: repeat(1, auto);
        grid-auto-columns: 170px;
        @include sp {
            grid-template-rows: repeat(2, auto);
            grid-auto-columns: 125px;
        }
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        isolation: isolate;
    }
}