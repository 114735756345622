// base.scss

.flex {
    display: flex;
  }
  
  .bg-f8f8f8 {
    background-color: $color-bg-f8f8f8;
  }
  
  main {
    min-height: 80vh;
  }
  
  .rating {
    span {
      margin: 0.1rem;
    }
    svg {
      color: $color-rating;
    }
  }
  
  .rating-shop {
    font-size: $font-size-large;
    font-weight: 500;
    padding-left: 1.2rem;
  }
  
  .rating-text {
    font-size: $font-size-small;
    font-weight: 600;
    padding-left: 0.5rem;
  }
  
  table {
    td,
    th {
      text-align: center;
    }
  }
  
  .review {
    margin-top: 30px;
    h2 {
      font-size: 24px;
      background: #f4f4f4;
      padding: 10px;
      border: 1px solid #ddd;
    }
    button {
      margin-top: 10px;
    }
  }
  
  .carousel-caption {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color-overlay;
  }
  
  h1 {
    color: #000b29;
  }  