// qr-reader.scss

.qr-reader-container {
    video {
        transform: scaleX(-1); // 左右反転
        width: 100%; // 幅を親コンテナに合わせる
        height: auto; // 高さを自動調整
        border: 2px solid #ccc; // 枠線を追加（オプション）
        border-radius: 8px; // 角を丸める（オプション）
    }
}   