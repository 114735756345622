// header.scss

.header {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
    height: 50px;
    width: 100vw;
    font-size: $font-size-base;
    color: $color-text;
    background-color: $color-header-bg;
    box-shadow: none;
  
    .header-wrapper {
        @include flex-center;
        justify-content: space-between;
        width: 980px;
        @include tablet {
            width: 90%;
        }
        height: 50px;
        padding: 0;
        margin: 0 auto;

        .dropdown-item {
            color: $color-dropdown-item;
            &.active {
                color: #fff;
            }
        }
    
        .logo {
            font-family: 'Noto Serif JP';
            font-size: 1.2rem;
            font-weight: bold;
            cursor: pointer;
        }
    
        .header-components {
            @include flex-center;
            margin-left: auto;
            @include sp {
                margin-left: 0;
            }
    
            .header-fa-container {
                @include flex-center;
                position: relative;
                font-size: 12px;
                font-weight: bold;
                cursor: pointer;
                width: 50px;
                height: 50px;
                @include sp {
                    width: 30px;
                    height: 30px;
                }
                padding: 0;
                background: none;
                border: none;
                border-radius: 0;
                box-shadow: none;
                color: $color-text;
        
                &:hover {
                    background-color: #e0e0e0;
                }
        
                .header-fa {
                    height: 22px;
                    width: 24px;
                    font-size: 20px;
                    @include sp {
                        width: 22px;
                    }
                }
        
                .badge {
                    position: absolute;
                    top: -5px;
                    right: -10px;
                }
            }
    
            .header-notification {
                width: 60px;
        
                &::after {
                    display: none;
                }
        
                .header-notification-menu {
                    max-width: 350px;
        
                    .notification-item {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
        
                    .notification-unread-dot {
                        color: $color-notification-dot;
                        margin-right: 6px;
                        font-size: 0.4rem;
                    }
                }
            }
    
            .header-admin {
                width: 70px;
            }
    
            .dropdown-toggle::after {
                color: $color-text;
            }
        }
    }
}

footer {
    width: 100vw;
}