.category-search {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid rgba(45, 45, 45, 0.05);
  
    &-title {
      margin-bottom: 10px;
  
      h3 {
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        word-wrap: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        font-size: 15px;
        line-height: 1.7333;
        color: #2d2d2d;
      }
    }
  }
  
  .parent-category {
    position: relative;
  
    &:hover {
      background-color: rgba(45, 45, 45, 0.05);
    }
  
    &-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 10px;
      font-size: 13px;
      color: #2d2d2d;
    }
  
    &-chevron {
      flex-shrink: 0;
      width: 6px;
      height: 20px;
      margin-left: 10px;
      fill: #a0a0a0;
    }
  }
  
  .category-popup {
    @include category-popup-styles;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s ease-out, opacity 0.2s ease-out;
  }
  
  .category-popup-visible {
    @include category-popup-styles;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s ease-in, opacity 0.2s ease-in;
  }
  
  .child-category-wrapper {
    min-width: 240px;
    padding: 12px 0;
    font-size: 13px;
  }
  
  .child-category {
    display: block;
    padding: 7px 20px;
    color: #2d2d2d;
  
    &:hover {
      background-color: rgba(45, 45, 45, 0.05);
    }
  }
  