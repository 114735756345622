.shop-edit-btn {
    position: sticky;
    top: 60px;
    left: 0;
    z-index: 100;
  }
  
  .edit-panel {
    position: fixed;
    top: 0;
    right: -100%; // 初期状態では画面外
    width: 300px; // 編集パネルの幅
    height: 100%;
    background-color: white;
    transition: right 0.5s ease-in-out;
    overflow-y: scroll;
  
    &.show {
      right: 0; // 表示状態
    }
  }
  
  .shop-top {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .custom-range {
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, transparent 48%, #2d2d2d 48%, #2d2d2d 52%, transparent 52%);
      border-radius: 4px; // トラックの角を丸くする
    }
  
    &::-moz-range-track {
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, transparent 48%, #2d2d2d 48%, #2d2d2d 52%, transparent 52%);
      border-radius: 4px;
    }
  
    &::-ms-track {
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, transparent 48%, #2d2d2d 48%, #2d2d2d 52%, transparent 52%);
      border-radius: 4px;
    }
  }
  
  .shop-home-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  
  .border-top-bottom {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
  }
  
  .li-separator {
    li:not(:last-child) {
      border-right: 1px solid black;
    }
  }  