// typography.scss

body {
    font-family: $font-main;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $color-text;
    background-color: $color-background;
    @include font-smoothing;
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: #000b29;
  }
  
  .small-text {
    font-size: 0.7rem;
  }
  
  .smaller-text {
    font-size: 0.5rem;
  }  