// components/shop.scss

.shop-nav {
    display: flex;
    align-items: center;
  
    .shop-nav-list {
      display: flex;
      align-items: center;
      list-style: none;
      font-size: 13px;
      line-height: 1.1;
  
      & + .shop-nav-list::before {
        display: block;
        width: 1px;
        height: 1.1em;
        margin: 0 10px;
        content: "";
        background-color: rgba(45, 45, 45, 0.15);
      }
  
      a {
        color: $color-link;
      }
    }
  }
  
  .shop-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
  }
  
  .shop-card {
    height: 120px;
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .shop-logo {
      height: 80px;
      width: 80px;
      margin: 0 auto;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      object-position: center;
    }
  
    .shop-name {
      height: 40px;
      padding-top: 4px;
      font-size: 12px;
      text-align: center;
    }
}