// buttons.scss

.button {
    background-color: $color-bg-f8f8f8;
    border: 1px solid $color-border;
    padding: 10px 20px;
    font-size: $font-size-base;
    cursor: pointer;
  
    @include hover-bg(rgba(45, 45, 45, 0.05));
}
  
.favorite-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    color: rgb(255, 255, 255);
    appearance: none;
    cursor: pointer;
    outline: 0;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 100%;
    position: absolute;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    
    &:hover {
        background-color: transparent;
    }
  
    &--left {
        left: 10px;
    }
  
    &--right, &--double-left {
        right: 10px;
    }
  
    &__icon {
        width: auto;
        height: 18px;
        fill: currentcolor;
    
        &--left, &--double-left {
            transform: translate(-1px, 0);
        }
    
        &--right {
            transform: translate(1px, 0);
        }
    }
}  