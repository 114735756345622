.carousel-caption {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color-overlay;
}

.product-carousel {
    background-color: #000b29;
}