.grid-display {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.2%;
}

.grid-item {
    position: relative;
    overflow: hidden;
    &:hover {
        img {
            transform: scale(1.1); // ホバー時に画像を拡大
        }
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include transition(transform 0.5s ease);
    }
    &__info {
        @include flex-center;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-overlay;
        color: white;
        padding: 10px;
        opacity: 0;
        @include transition(opacity 0.3s);
        font-size: 16px;
        font-weight: 600;

        &:hover {
            opacity: 1;
        }
    }
}