// utilities.scss

.flex {
    display: flex;
}
  
.bg-f8f8f8 {
    background-color: $color-bg-f8f8f8;
}

.small-text {
    font-size: 0.7rem;
}

.smaller-text {
    font-size: 0.5rem;
}

.image-container,
.thumbnail-container {
    aspect-ratio: 17 / 20;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 4px;
}

.search-box-container {
    margin-left: 20px;
    height: 40px;
    width: 420px;

    input {
        font-size: 0.8rem;
        height: 40px;
    }

    svg {
        vertical-align: initial;
    }
}

.full-link {
    display: block;
    width: 100%;
    height: 100%;
}

.toggle-list {
    background-color: #efefef;
}

.order-card {
    // width: fit-content;
    @include sp {
        padding-left: 0px;
    }
    .product-gallery{
        &__items {
            @include sp {
                grid-template-rows: repeat(1, auto);
                margin-bottom: 0px
            };
        }
        &__container {
            @include sp {
                margin-top: 0px;
            }
        }
    }
}