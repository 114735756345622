// rating.scss

.rating {
    span {
      margin: 0.1rem;
    }
  
    svg {
        color: $color-rating-svg;
    }
  
    &-shop {
      font-size: $font-size-large;
      font-weight: 500;
      padding-left: 1.2rem;
    }
  
    &-text {
      font-size: $font-size-small;
      font-weight: 600;
      padding-left: 0.5rem;
    }
  }  