.search-box-container {
    margin-left: 20px;
    height: 40px;
    width: 420px;
  
    svg {
        vertical-align: initial;
    }
}
  
.search-button {
    background-color: #fff;
    color: #2d2d2d;
    border-color: #b5c0c1;
    border-right: none;
    height: 40px;
}  